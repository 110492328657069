<template>
    <a-skeleton
      class="article-card-skeleton"
      active
      :title="{ width: 'full' }"
      :paragraph="{ rows: 3, width: ['full'] }"
    />
</template>

<style lang="scss">
.article-card-skeleton {
    margin-bottom: 3rem;
    .ant-skeleton-content {
      .ant-skeleton-title {
        height: 30rem;
        margin-top: 0;
      }
    }
  }
</style>