<template>
    <div class="article-grid-view">
      <a-row :gutter="22" v-if="searchingArticles">
        <a-col
          :xs="24"
          :sm="12"
          :md="12"
          :lg="8"
          v-for="index in 12"
          :key="index"
        >
          <article-card-skeleton></article-card-skeleton>
        </a-col>
    </a-row>
    <template v-else>
    <div v-if="$store.getters['discover/getLimitError']" class="message">
      Due to multiple access attempts, access in not available for 10 minutes. <br> Subscribe to experience the News Intelligence™ environment.
      <a-button @click="showSubscriptionModal()">Subscribe</a-button>
    </div>
    <div v-if="articles.length === 0 && !$store.getters['discover/getLimitError']" class="message">
      No results found
      <span v-if="$store.getters['discover/getSearchQuery']">
        for
        <b
          ><q>{{ $store.getters["discover/getSearchQuery"] }}</q></b
        >
      </span>
    </div>
    <a-row v-else :gutter="[18, 10]">
      <a-col :xs="24" :sm="24" :md="24" :lg="12" :xl="12" class="article-grid-view-col">
        <a-row :gutter="[18, 18]">
          <a-col v-if="articles[0]" :xs="24" :sm="12" :md="24" :lg="24" :xl="24">
             <article-card
              :title="articles[0].title[0]"
              :author-name="articles[0].authors[0]"
              :text="articles[0].description[0]"
              :image="articles[0].image[0]"
              :sourceLogo="articles[0].logo[0]"
              :source="articles[0].article_url.source"
              :profile-photo="'profile-pic.webp'"
              :url="articles[0].article_url.url"
              :id="articles[0].id[0]"
              :date="articles[0].pub_date[0]"
              :showShareButton="true"
              :showDltBtn="showDeleteButton"
              :showSaveBtn="true"
              :showUnsaveBtn="showUnsaveButton"
              :ratings="articles[0].rating"
              :aggregatedRating="articles[0].aggregated_rating"
              :userReaction="articles[0].user_reaction"
              :totalArticleReactions="articles[0].article_reactions"
              :userReactionRepresentation="articles[0].users_reaction_representation"
              :isInlineRatingColumn="12"
              :cardSize="true"
            ></article-card> 
          </a-col>
          <a-col v-if="articles[5]" :xs="24" :sm="12" :md="12" :lg="24" :xl="12">
            <article-card
              :title="articles[5].title[0]"
              :author-name="articles[5].authors[0]"
              :text="articles[5].description[0]"
              :image="articles[5].image[0]"
              :sourceLogo="articles[5].logo[0]"
              :source="articles[5].article_url.source"
              :profile-photo="'profile-pic.webp'"
              :url="articles[5].article_url.url"
              :id="articles[5].id[0]"
              :date="articles[5].pub_date[0]"
              :showShareButton="true"
              :showDltBtn="showDeleteButton"
              :showSaveBtn="true"
              :showUnsaveBtn="showUnsaveButton"
              :ratings="articles[5].rating"
              :aggregatedRating="articles[5].aggregated_rating"
              :userReaction="articles[5].user_reaction"
              :totalArticleReactions="articles[5].article_reactions"
              :userReactionRepresentation="articles[5].users_reaction_representation"
            ></article-card>
          </a-col>
          <a-col v-if="articles[6]" :xs="24" :sm="12" :md="12" :lg="24" :xl="12">
            <article-card
              :title="articles[6].title[0]"
              :author-name="articles[6].authors[0]"
              :text="articles[6].description[0]"
              :image="articles[6].image[0]"
              :sourceLogo="articles[6].logo[0]"
              :source="articles[6].article_url.source"
              :profile-photo="'profile-pic.webp'"
              :url="articles[6].article_url.url"
              :id="articles[6].id[0]"
              :date="articles[6].pub_date[0]"
              :showShareButton="true"
              :showDltBtn="showDeleteButton"
              :showSaveBtn="true"
              :showUnsaveBtn="showUnsaveButton"
              :ratings="articles[6].rating"
              :aggregatedRating="articles[6].aggregated_rating"
              :userReaction="articles[6].user_reaction"
              :totalArticleReactions="articles[6].article_reactions"
              :userReactionRepresentation="articles[6].users_reaction_representation"
            ></article-card>
          </a-col>
          <a-col v-if="articles[7]" :xs="24" :sm="12" :md="24" :lg="24" :xl="24">
            <article-card
              :title="articles[7].title[0]"
              :author-name="articles[7].authors[0]"
              :text="articles[7].description[0]"
              :image="articles[7].image[0]"
              :sourceLogo="articles[7].logo[0]"
              :source="articles[7].article_url.source"
              :profile-photo="'profile-pic.webp'"
              :url="articles[7].article_url.url"
              :id="articles[7].id[0]"
              :date="articles[7].pub_date[0]"
              :showShareButton="true"
              :showDltBtn="showDeleteButton"
              :showSaveBtn="true"
              :showUnsaveBtn="showUnsaveButton"
              :ratings="articles[7].rating"
              :aggregatedRating="articles[7].aggregated_rating"
              :userReaction="articles[7].user_reaction"
              :totalArticleReactions="articles[7].article_reactions"
              :userReactionRepresentation="articles[7].users_reaction_representation"
              :isInlineRatingColumn="12"
              :cardSize="true"
            ></article-card>
          </a-col>
        </a-row>
      </a-col>
      <a-col :xs="24" :sm="24" :md="24" :lg="12" :xl="12" class="article-grid-view-col">
        <a-row :gutter="[18, 18]" class="right-grid">
          <a-col v-if="articles[1]" :xs="24" :sm="12" :md="24" :lg="24" :xl="12">
            <article-card
              :title="articles[1].title[0]"
              :author-name="articles[1].authors[0]"
              :text="articles[1].description[0]"
              :image="articles[1].image[0]"
              :sourceLogo="articles[1].logo[0]"
              :source="articles[1].article_url.source"
              :profile-photo="'profile-pic.webp'"
              :url="articles[1].article_url.url"
              :id="articles[1].id[0]"
              :date="articles[1].pub_date[0]"
              :showShareButton="true"
              :showDltBtn="showDeleteButton"
              :showSaveBtn="true"
              :showUnsaveBtn="showUnsaveButton"
              :ratings="articles[1].rating"
              :aggregatedRating="articles[1].aggregated_rating"
              :userReaction="articles[1].user_reaction"
              :totalArticleReactions="articles[1].article_reactions"
              :userReactionRepresentation="articles[1].users_reaction_representation"
            ></article-card>
          </a-col>
          <a-col v-if="articles[2]" :xs="24" :sm="12" :md="12" :lg="24" :xl="12">
            <article-card
              :title="articles[2].title[0]"
              :author-name="articles[2].authors[0]"
              :text="articles[2].description[0]"
              :image="articles[2].image[0]"
              :sourceLogo="articles[2].logo[0]"
              :source="articles[2].article_url.source"
              :profile-photo="'profile-pic.webp'"
              :url="articles[2].article_url.url"
              :id="articles[2].id[0]"
              :date="articles[2].pub_date[0]"
              :showShareButton="true"
              :showDltBtn="showDeleteButton"
              :showSaveBtn="true"
              :showUnsaveBtn="showUnsaveButton"
              :ratings="articles[2].rating"
              :aggregatedRating="articles[2].aggregated_rating"
              :userReaction="articles[2].user_reaction"
              :totalArticleReactions="articles[2].article_reactions"
              :userReactionRepresentation="articles[2].users_reaction_representation"
            ></article-card>
          </a-col>
          <a-col v-if="articles[3]" :xs="24" :sm="12" :md="24" :lg="24" :xl="24">
            <article-card
              :title="articles[3].title[0]"
              :author-name="articles[3].authors[0]"
              :text="articles[3].description[0]"
              :image="articles[3].image[0]"
              :sourceLogo="articles[3].logo[0]"
              :source="articles[3].article_url.source"
              :profile-photo="'profile-pic.webp'"
              :url="articles[3].article_url.url"
              :id="articles[3].id[0]"
              :date="articles[3].pub_date[0]"
              :showShareButton="true"
              :showDltBtn="showDeleteButton"
              :showSaveBtn="true"
              :showUnsaveBtn="showUnsaveButton"
              :ratings="articles[3].rating"
              :aggregatedRating="articles[3].aggregated_rating"
              :userReaction="articles[3].user_reaction"
              :totalArticleReactions="articles[3].article_reactions"
              :userReactionRepresentation="articles[3].users_reaction_representation"
              :isInlineRatingColumn="12"
              :cardSize="true"
            ></article-card>
          </a-col>
          <a-col v-if="articles[4]" :xs="24" :sm="12" :md="12" :lg="24" :xl="12">
            <article-card
              :title="articles[4].title[0]"
              :author-name="articles[4].authors[0]"
              :text="articles[4].description[0]"
              :image="articles[4].image[0]"
              :sourceLogo="articles[4].logo[0]"
              :source="articles[4].article_url.source"
              :profile-photo="'profile-pic.webp'"
              :url="articles[4].article_url.url"
              :id="articles[4].id[0]"
              :date="articles[4].pub_date[0]"
              :showShareButton="true"
              :showDltBtn="showDeleteButton"
              :showSaveBtn="true"
              :showUnsaveBtn="showUnsaveButton"
              :ratings="articles[4].rating"
              :aggregatedRating="articles[4].aggregated_rating"
              :userReaction="articles[4].user_reaction"
              :totalArticleReactions="articles[4].article_reactions"
              :userReactionRepresentation="articles[4].users_reaction_representation"
            ></article-card>
          </a-col>
          <a-col v-if="articles[8]" :xs="24" :sm="12" :md="24" :lg="24" :xl="12">
            <article-card
              :title="articles[8].title[0]"
              :author-name="articles[8].authors[0]"
              :text="articles[8].description[0]"
              :image="articles[8].image[0]"
              :sourceLogo="articles[8].logo[0]"
              :source="articles[8].article_url.source"
              :profile-photo="'profile-pic.webp'"
              :url="articles[8].article_url.url"
              :id="articles[8].id[0]"
              :date="articles[8].pub_date[0]"
              :showShareButton="true"
              :showDltBtn="showDeleteButton"
              :showSaveBtn="true"
              :showUnsaveBtn="showUnsaveButton"
              :ratings="articles[8].rating"
              :aggregatedRating="articles[8].aggregated_rating"
              :userReaction="articles[8].user_reaction"
              :totalArticleReactions="articles[8].article_reactions"
              :userReactionRepresentation="articles[8].users_reaction_representation"
            ></article-card>
          </a-col>
        </a-row>
      </a-col>
    </a-row>
  </template>
  </div>

  <subscription-modal 
    :visible="visibleSubscriptionModal"
    :visibleSignUp="visibleSubscriptionModal" 
    @cancel="visibleSubscriptionModal = false"
    :footer="null"
  ></subscription-modal>

</template>

<script>
import SubscriptionModal from "../../components/Auth/AccessModal.vue"
import ArticleCard from "../BaseComponents/ArticleCard.vue"
import ArticleCardSkeleton from "../BaseComponents/ArticleCardSkeleton.vue"

export default {
  props: {
    activeSection: String
  },
  components: {
    ArticleCard,
    SubscriptionModal,
    ArticleCardSkeleton
  },
  data() {
    return {
      showDeleteButton: false,
      showUnsaveButton: false,
      showSavedArticlesButton: true,
      visibleSubscriptionModal: false,
    };
  },
  computed: {
    mobileView() {
      return this.$store.getters["discover/getSearchedArticles"];
    },
    desktopSkeleton() {
      const insight = this.$store.getters["discover/getInsightsSkeletonLoader"];
      const news = this.$store.getters["discover/getPDBSkeletonLoader"];
      if (this.activeSection === 'Trending News') {
        return news
      }
      return insight;
    },
    searchingArticles() {
      if (window.innerWidth <= 576) {
        return this.$store.getters["discover/getPDBSkeletonLoader"];
      }
      return this.desktopSkeleton;
    },
    desktopView() {
      const newsArticles = this.$store.getters["discover/getSearchedArticles"];
      const insights = this.$store.getters["discover/getInsights"];
      if(this.activeSection === 'Trending News'){
        return newsArticles
      }
      return insights;
    },
    articles(){
      if(window.innerWidth <= 576){
        return this.mobileView;
      }
      return this.desktopView;
    }
  },
  methods: {
    showSubscriptionModal(){
      this.visibleSubscriptionModal = true;
      this.$store.commit('profile/setSubscriptionModalVisisblity', false)
    },
  }
};
</script>
<style lang="scss">
.article-grid-view {
  .ant-row {
    margin-bottom: 2rem;
    .article-grid-view-col {
      height: fit-content;
    }
  }
  .article-card {
    height: 100%;
    margin-bottom: 0;
    display: flex;
    flex-direction: column;
    background-color: #f5f5f5cb !important;
    padding-bottom: 0.5rem;
  }

  .right-grid {
    height: 100%;
    .card-text {
      text-overflow: ellipsis;
    }
    .card-heading {
      font-size: 1.6rem;
      text-overflow: ellipsis;
    }
  }
  .message {
    font-size: 2rem;
    text-align: center;
    display: flex;
    align-items: center;
    flex-direction: column;
    margin-top: 20rem;
    .ant-btn {
      border: none;
      font-size: 1.6rem;
      font-family: $font-secondary-bold;
      cursor: pointer;
      background-color: $color-primary;
      color: $color-white;
      transition: all 0.2s;
      height: 4rem;
      width: 14rem;
      margin-top: 3rem;
      &:hover,
      &:active,
      &:focus {
        background-color: $color-primary;
        color: $color-white;
        border: none;
      }
      span {
        line-height: normal;
      }
    }
  }
  .sidebar-search-filter {
    position: sticky;
    top: 12.6rem;
  }
}
</style>
