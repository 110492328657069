<template>
  <!-- This div is representing the Article Read view on PDB page. -->
  <div class="pdb-insights" v-if="showArticleReadView">
    <PDBArticleReadView></PDBArticleReadView>
  </div>
  <!-- This is the main div on PDB page -->
  <div class="pdb-insights" v-if="!showArticleReadView">
    <!-- This component is display all the topics on PDB  -->
    <topic-filter-navigator></topic-filter-navigator>
    <a-row :gutter="[18, 18]" class="pdb-insights__body">
      <a-col :xs="24" :sm="24" :md="24" :lg="24" v-if="!visibleDraggable" class="pdb-insights__body--grid-row">
        <article-grid-view v-if="activeNewsContainer"></article-grid-view>
        <insight-view v-else-if="activeInsigtsContainer"></insight-view>
      </a-col>
      <!-- This component is display A modules of Insihgts/ Trending News draggable -->
      <a-col :xs="24" :sm="24" :md="24" :lg="24" v-if="searchingModules" class="pdb-insights__body--skeleton-row">
        <a-row :gutter="[22, 22]">
          <a-col :xs="24" :sm="12" :md="8" :lg="6" v-for="index in 12" :key="index">
            <article-card-skeleton></article-card-skeleton>
          </a-col>
        </a-row>
      </a-col>
      <div  class="pdb-insights__body--pdb-row" v-if="!searchingModules && visibleDraggable">
        <NewsSection />
        <Collaborate />
      </div>
    </a-row>
    <the-pagination v-if="activeNewsContainer"
      class="pdb-insights__pagination-container" 
      @change-page="searchNewsArticles($event)" 
      @move-first-page="goToNewsFirstPage"  
      @move-last-page="goToNewsLastPage"
      :pageNumber="newsPageNumber"
      :totalPages="newsTotalPages" 
      :results="newsArticles">
    </the-pagination>
    <the-pagination v-if="activeInsigtsContainer"
      class="pdb-insights__pagination-container" 
      @change-page="searchInsightsArticles($event)" 
      @move-first-page="goToInsightsFirstPage" 
      @move-last-page="goToInsightsLastPage"
      :pageNumber="insightsPageNumber"
      :totalPages="insightsTotalPages" 
      :results="insightsArticles">
    </the-pagination>

    <!-- Message toaster highlighting success or failure messages -->
    <message-toaster :visible="showMessageModal" :message="message" @handle-close="showMessageModal = false">
    </message-toaster>
  </div>

  <!-- Subscription modal for non-subscribe user -->
  <subscription-modal :visible="visibleSubscriptionModal" :visibleSignUp="visibleSubscriptionModal" :footer="null"
    @cancel="visibleSubscriptionModal = false"></subscription-modal>

</template>

<script>
import NewsSection from "./NewsSection.vue";
// import InsightsSection from "./InsightsSection.vue";
import ArticleGridView from "./ArticleGridView.vue";
import InsightView from "./InsightView.vue";
import SubscriptionModal from "../../components/Auth/AccessModal.vue";
import MessageToaster from "../BaseComponents/MessageToaster.vue";
import TopicFilterNavigator from "../BaseComponents/DiscoverTopicFilterNavigator.vue";
import PDBArticleReadView from "../ArticleReadComponents/PDBArticleReadView.vue";
import ThePagination from "../BaseComponents/CustomPagination.vue";
import ArticleCardSkeleton from "../BaseComponents/ArticleCardSkeleton.vue";
import Collaborate from "./Collaborate.vue"

export default {
  components: {
    NewsSection,
    // InsightsSection,
    InsightView,
    ArticleGridView,
    SubscriptionModal,
    MessageToaster,
    TopicFilterNavigator,
    PDBArticleReadView,
    ThePagination,
    ArticleCardSkeleton,
    Collaborate
  },
  data() {
    return {
      searchingModules: true,
      showMessageModal: false,
      message: {},
      visibleSubscriptionModal: false,
      visibleRequestSourceModal: true,
      visibleRequestNewsSourceDrawer: false,
      visibleRequestInsightSourceDrawer: false,
      articlesPerPage: 10,
      visibleDraggable: true,
      activeNewsContainer: true,
      activeInsigtsContainer: true,
    };
  },
  computed: {
    getDiscoverModules() {
      const value = this.$store.getters["discover/getDiscoverModules"];
      let parentSection = value.find(parent => parent.module_name === 'Discover');
      return parentSection.child;
    },
    moduleFirstChild() {
      return this.getDiscoverModules;
    },
    showArticleReadView() {
      return this.$store.getters["discover/getArticleReadView"];
    },
    newsResults() {
      return {
        value: this.$store.getters["discover/getArticleTotalResults"].value,
        relation: this.$store.getters["discover/getArticleTotalResults"].relation,
      };
    },
    newsTotalPages() {
      if (this.newsResults.value) {
        return Math.ceil(this.newsResults.value / this.articlesPerPage);
      }
      return 0;
    },
    newsPageNumber() {
      return this.$store.getters["discover/getCurrenPageNumber"];
    },
    newsArticles() {
      return this.$store.getters["discover/getTotalArticles"];
    },
    newsPageOffset: {
      get() {
        return this.$store.getters["discover/getArticlesPageOffset"];
      },
      set(val) {
        this.$store.commit("discover/SET_ARTICLES_PAGE_OFFSET", val)
      }
    },
    insightsResults() {
      return {
        value: this.$store.getters["discover/getInsightsTotalResults"].value,
        relation: this.$store.getters["discover/getInsightsTotalResults"].relation,
      };
    },
    insightsTotalPages() {
      if (this.insightsResults.value) {
        return Math.ceil(this.insightsResults.value / this.articlesPerPage);
      }
      return 0;
    },
    insightsPageNumber() {
      return this.$store.getters["discover/getInisghtsCurrenPageNumber"];
    },
    insightsArticles(){
      return this.$store.getters["discover/getInsightsTotalArticles"];
    },
    insightsPageOffset: {
      get() {
        return this.$store.getters["discover/getInsightsPageOffset"];
      },
      set(val) {
        this.$store.commit("discover/SET_INSIGHTS_PAGE_OFFSET", val)
      }
    },
    userId() {
      return this.$store.getters["auth/getUserId"]
    }
    
  },
  watch: {
    $route: {
      handler(to) {
        if (to.path === "/discover") {
          this.$store.commit("discover/SET_ARTICLE_READ_VIEW", false);
        }
      },
      immediate: true,
    },
  },
  async created() {
    if (this.$route.params.articleId) {
      this.$store.commit("discover/SET_ARTICLE_READ_VIEW", true);
    }
  },
  async mounted() {
    this.emitter.on('searchingModules', (val) => {
      this.searchingModules = val;
    });

    window.addEventListener("resize", async (val)=> {
      if (window.innerWidth <= 576) {
        this.visibleDraggable = false;
        this.searchingModules = false;
        this.activeNewsContainer = true;
        this.activeInsigtsContainer = false;
      } else {
        this.visibleDraggable = true;
      }
    });
      if (window.innerWidth <= 576) {
        this.visibleDraggable = false;
        this.searchingModules = false;
        this.activeNewsContainer = true;
        this.activeInsigtsContainer = false;
      } else {
        this.visibleDraggable = true;
      }

    this.emitter.on("showNewsTab", (val) => {
      if (val === "news-tab") {
        this.activeNewsContainer = true;
        this.activeInsigtsContainer = false;
      } else {
        this.activeInsigtsContainer = true;
        this.activeNewsContainer = false;
      }
    });

    // this.emitter.on('openCollapseSection', (val) => {
    //   val ? this.opendiscussionCollapse = ' pdb-discussion-collapse-opened' : this.opendiscussionCollapse = '';
    // });

    this.fetchContacts();
    document.addEventListener("click", this.handleClickOutside);
    this.$store.dispatch("discover/getAllSources");
    this.$store.dispatch("discover/getAllInsightsSources");

    this.emitter.on("topicAddedSuccMsg", () => {
      this.showMessageModal = true;
      this.message = {
        title: "Topic Added Successfully",
        type: "success",
      };
    });

    this.emitter.on("closeNewsSourceModal", () => {
      this.showMessageModal = true;
      this.visibleRequestSourceModal = false;
      this.message = {
        title: "We Received Your Request. Thank You.",
        type: "success",
      };
    });
  },
  methods: {
    replaceModuleName(name) {
      let newName = "";
      switch (name) {
        case "Trending News":
          newName = "news-section"
          break;
        case "Insights":
          newName = "insights-section"
          break;
        default:
          break;
      }
      return newName;
    },  
    dragModule() {
      this.$store.commit('discover/SEARCHING_PDB_ARTICLES', true);
      this.$store.commit('discover/SEARCHING_INSIGHTS_ARTICLES', true);
    },
    async updateOrdering(order) {
      try {
        const newOrder = order.order === 0 ? 1 : 0;
        const payload = {
          order: newOrder,
          id: order.id
        }
        await this.$store.dispatch("discover/updateDiscoverModules", payload);
        this.$store.commit('discover/SEARCHING_PDB_ARTICLES', false);
        this.$store.commit('discover/SEARCHING_INSIGHTS_ARTICLES', false);
      } catch (err) {
        console.log(err);
      }
    },
    handleClickOutside(event) {
      const element = this.$el;
      if (!element.contains(event.target)) {
        this.emitter.emit("closeTopicBar", false);
      }
    },
    openPopper() {
      this.visibleRequestSourceModal = true;
      this.emitter.emit("clearFields");
    },
    async fetchContacts() {
      try {
        this.contacts = [];
        const payload = {
          user_id: this.userId,
        };
        if (this.userId) {
          const response = await this.$store.dispatch(
            "profile/getFriendsList",
            payload
          );
          if (response.status == 200) {
            this.contacts = response.data.friends.map((val) => {
              if (val.profile) {
                let userList = {
                  label: val.profile.full_name,
                  value: val.id,
                  key: val.email,
                  icon: val.profile.profile_picture,
                };
                return userList;
              }
            });
          }
        }
      } catch (err) {
        console.log(err);
      }
    },
    showRequestNewsSourceDrawer() {
      this.visibleRequestNewsSourceDrawer = true;
    },
    showRequestInsightSourceDrawer() {
      this.visibleRequestInsightSourceDrawer = true;
    },
    closeRequestSourcesDrawer() {
      this.visibleRequestNewsSourceDrawer = false;
      this.visibleRequestInsightSourceDrawer = false;
    },
    async searchNewsArticles(page) {
      this.newsPageOffset = page === 'next' ? this.newsPageOffset + 9 : Math.max(0, this.newsPageOffset - 9);
      await this.$store.dispatch("discover/searchArticles");
    },
    async searchInsightsArticles(page) {
      this.insightsPageOffset = page === 'next' ? this.insightsPageOffset + 9 : Math.max(0, this.insightsPageOffset - 9);
      await this.$store.dispatch("discover/searchInsights");
    },
    async goToNewsFirstPage(){
      this.newsPageOffset = 0;
      await this.$store.dispatch("discover/searchArticles");
    },
    async goToNewsLastPage(){
      this.insightsPageOffset = this.newsResults.value - 2;
      await this.$store.dispatch("discover/searchInsights");
    },
    async goToInsightsFirstPage(){
      this.insightsPageOffset = 0;
      await this.$store.dispatch("discover/searchInsights");
    },
    async goToInsightsLastPage(){
      this.insightsPageOffset = this.insightsResults.value - 2;
      await this.$store.dispatch("discover/searchInsights");
    },
  },
};
</script>

<style lang="scss">
@include reset-css;

.pdb-insights {
  padding-bottom: 0;
  padding-top: 0;
  position: relative;
  z-index: 1;
  background-color: white;
  margin-top: 7.8rem;

  @include respond(phone-x-small) {
    margin-top: 10.8rem;
  }

  &__body {
    padding: 0 2rem;
    @include respond(phone-x-small) {
      padding: 0 3rem;
    }
    .request-btn {
      color: #494848;
      font-family: $font-primary-medium;
      font-size: 1.6rem;
      background-color: transparent;
      height: fit-content;
      outline: none;
      border: none;
      cursor: pointer;
      line-height: normal;
      position: relative;
      display: flex;
      align-items: center;
      line-height: 1.8rem;
      padding: 0.5rem;
      @media (min-width: 1200px) and (max-width: 1300px) {
        font-size: 1.5rem;
      }
      .icon {
        margin-right: 0.8rem;
        font-size: 1rem;
        vertical-align: middle;
        margin-bottom: 0.1rem;
      }

      @include respond(tab-port) {
        font-size: 2rem;

        .icon {
          font-size: 1.5rem;
        }
      }

      @include respond(phone-x-small) {
        font-size: 1.8rem;

        .icon {
          font-size: 1.4rem;
        }
      }
    }

    .apply-filter-mobile {
      display: none;
      border-bottom: 1px solid #d6d6d6;

      .apply-filter {
        padding: 2.4rem 0 2rem;
        border-top: none;
      }

      @include respond(phone-x-small) {
        display: block;
      }
    }

    &--skeleton-row {
      margin-top: 3rem;

      @include respond(phone-x-small) {
        margin-top: 1rem;
      }
    }
    &--pdb-row {
      width: 100%;
      display: grid;
      margin-top: 1.3rem;
      grid-template-columns: 69% 31%;
      @include respond(laptop-medium) {
        grid-template-columns: 68% 32%;
      }

      @media (min-width: 1200px) and (max-width: 1300px) {
        grid-template-columns: 65% 35% !important;
      }

      @include respond(tab-land) {
        grid-template-columns: 58% 42%;
      }

      @include respond(laptop-small) {
        grid-template-columns: 54% 46%;
      }

      @include respond(tab-port) {
        grid-template-columns: auto;
      }

      @include respond(phone-x-small) {
        display: none;
      }
    }
    &--grid-row {
      display: none;
      @include respond(phone-x-small) {
        display: block;
        margin-top: 2.5rem;
      }
    }
  }

  &__pagination-container {
    display: none;
    padding: 3rem;
    margin-bottom: 0;

    @include respond(phone-x-small) {
      display: block;
    }

    .pagination-container__pagination {
      width: fit-content;
      margin: 0 auto;

      .btn {
        font-size: 2.1rem;

        .icon-forward {
          font-size: 1.8rem;
          color: $color-black;
        }

        .prev {
          margin-right: 1rem;
          margin-left: 0;
        }

        .next {
          margin-left: 1rem;
          margin-right: 0;
        }
      }

      .counter {
        font-size: 2.1rem;
      }
    }
  }
  &__collapse-opened {
    margin-bottom: 12rem;
  }
}
</style>
