<template>
  <div class="ai-comment-card">
    <div class="ai-comment-card__ai-answer">
      <img src="@/assets/icons/glocal-ai-icon-navbar-black.svg" />
      <div v-if="!botMessage.message" class="ai-comment-card__ai-answer--typing">
        <span></span>
        <span></span>
        <span></span>
      </div>
      <h4 v-else>
        <div v-if="highlightedMessage.length>0" v-html="formattedMessage(highlightedMessage)"></div>
        <div v-else v-html="formattedMessage(botMessage.message)"></div>
      </h4>
       <a-dropdown
          @click.stop
          :trigger="['click']"
          placement="bottomRight"
        >
          <a class="ant-dropdown-link">
            <i class="icon icon-vertical_three-dots"></i>
          </a>
          <template #overlay>
            <a-menu>
              <a-menu-item key="0" @click="copyText()">
                <a>Copy response</a>
              </a-menu-item>
            </a-menu>
          </template>
        </a-dropdown>
    </div>
  </div>
</template>

<script>
import { marked } from 'marked';
export default {
  props: {
    'botMessage': {
      default: () => ({
        message:null
      }),
    },
    'highlightedMessage': {
      default: () => ({
        message:null
      }),
    },
  },
  methods: {
    formattedMessage(message) {
      return marked.parse(message);
    },
    copyText() {
      const textToCopy = this.botMessage.message;
      // Create a temporary textarea element
      const textarea = document.createElement('textarea');
      textarea.value = textToCopy;
      textarea.style.position = 'fixed';
      textarea.style.left = '-9999px';
      document.body.appendChild(textarea);
      textarea.select();
      document.execCommand('copy');
      document.body.removeChild(textarea);
    },
  }
};  
</script>

<style lang="scss">
.ai-comment-card {
  margin: 3.2rem 0 4.2rem;
  &__ai-answer {
    display: flex;
    align-items: flex-start;
    img {
      width: 4.2rem;
      height: 4.2rem;
      border-radius: 100%;
      object-fit: fill;
      margin-right: 2.1rem;
    }
    h4 {
      font-size: 1.8rem;
      font-family: $font-primary;
      margin-bottom: 0;
      color: $color-black;
      line-height: 2.4rem;
      margin-top: 0.5rem;
    }
    .ant-dropdown-link {
      margin-left: auto;
      margin-top: 0.7rem;
      .icon {
        color: $color-black;
      }
    }
    &--typing {
      position: relative;
      margin-top: 2rem;
      span {
        content: '';
        animation: blink 1.5s infinite;
        animation-fill-mode: both;
        height: 1rem;
        width: 1rem;
        background: $color-primary;
        position: absolute;
        left:0;
        top:0;
        border-radius: 50%;
        
        &:nth-child(2) {
          animation-delay: .2s;
          margin-left: 1rem * 1.5;
        }
        
        &:nth-child(3) {
          animation-delay: .4s;
          margin-left: 1rem * 3;
        }
      }
    }


    @keyframes blink {
      0% {
        opacity: .1;
      }
      20% {
        opacity: 1;
      }
      100% {
        opacity: .1;
      }
    }
  }
}
</style>
