<template>
  <div class="collaborate-container">
    <div class="collaborate-container__header">
      <h1 class="collaborate-container__header--title">Collaborate</h1>
      <div class="collaborate-container__header--tabs">
        <div class="glocal-ai-btn" :class="{'glocal-ai-btn-active': getInterestGroup.length != 0 && !toggleToComments === true}">
          <button @click="toggleToComment">
            <img
              v-if="getInterestGroup.length != 0 && !toggleToComments === true"
              src="@/assets/icons/glocal-ai-icon-navbar-white.svg"
              alt="Icon"
            />
            <img
              v-else
              src="@/assets/icons/glocal-ai-icon-navbar-black.svg"
              alt="Icon"
            />
            
          </button>
          <a-dropdown @click.stop :trigger="['click']" placement="bottomRight">
            <a class="ant-dropdown-link">
              <i class="icon icon-vertical_three-dots"></i>
            </a>
            <template #overlay>
              <a-menu>
                <a-menu-item
                  key="0"
                  @click="openCollaboratePreviousSearchesDrawer()"
                >
                  <a>Previous Searches</a>
                </a-menu-item>
              </a-menu>
            </template>
          </a-dropdown>
        </div>
        <button
          v-if="getInterestGroup.length > 1 && getInterestGroup.length != 0"
          :class="activeTab === 'public' ? 'active' : ''"
          class="btn"
          @click="changeTab('public')"
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            height="48"
            viewBox="0 96 960 960"
            width="48"
          >
            <path
              d="M480 976q-83 0-156-31.5T197 859q-54-54-85.5-127T80 576q0-83 31.5-156T197 293q54-54 127-85.5T480 176q83 0 156 31.5T763 293q54 54 85.5 127T880 576q0 83-31.5 156T763 859q-54 54-127 85.5T480 976Zm-43-61v-82q-35 0-59-26t-24-61v-44L149 497q-5 20-7 39.5t-2 39.5q0 130 84.5 227T437 915Zm294-108q22-24 38.5-51t28-56.5q11.5-29.5 17-60.5t5.5-63q0-106-58-192.5T607 257v18q0 35-24 61t-59 26h-87v87q0 17-13.5 28T393 488h-83v88h258q17 0 28 13t11 30v127h43q29 0 51 17t30 44Z"
            />
          </svg>
          <p class="name">Public</p>
          <div class="dropdown">
            <button
              @click.stop="toggleDropdown('public')"
              class="dropdown__dropdown-btn"
            >
              <i class="icon icon-vertical_three-dots"></i>
            </button>
            <ul v-if="isPublicDropdownOpen" class="dropdown__dropdown-menu">
              <li @click="visibleInvitationCategory()">
                <span>Add Contacts</span>
              </li>
              <li @click="showDatePicker">
                <span>Date Range</span>
              </li>
              <a-range-picker
                v-if="isDatePickerVisible"
                status="warning"
                :ranges="{
                  Today: [moment(), moment()],
                  'This Month': [moment(), moment().endOf('month')],
                }"
                style="width: 100%;"
                @change="handleDateChange"
                :open="true"
              />
            </ul>
          </div>
        </button>
        <button
          v-if="getInterestGroup.length != 0"
          :class="activeTab === 'private' ? 'active' : ''"
          @click="changeTab('private')"
          class="btn"
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            height="48"
            viewBox="0 96 960 960"
            width="48"
          >
            <path
              d="M220 976q-24.75 0-42.375-17.625T160 916V482q0-24.75 17.625-42.375T220 422h70v-96q0-78.85 55.606-134.425Q401.212 136 480.106 136T614.5 191.575Q670 247.15 670 326v96h70q24.75 0 42.375 17.625T800 482v434q0 24.75-17.625 42.375T740 976H220Zm260.168-200Q512 776 534.5 753.969T557 701q0-30-22.668-54.5t-54.5-24.5Q448 622 425.5 646.5t-22.5 55q0 30.5 22.668 52.5t54.5 22ZM350 422h260v-96q0-54.167-37.882-92.083-37.883-37.917-92-37.917Q426 196 388 233.917 350 271.833 350 326v96Z"
            />
          </svg>
          <p class="name">Private</p>
          <div class="dropdown">
            <button
              @click.stop="toggleDropdown('private')"
              class="dropdown__dropdown-btn"
            >
              <i class="icon icon-vertical_three-dots"></i>
            </button>
            <ul v-if="isPrivateDropdownOpen" class="dropdown__dropdown-menu">
              <li @click="visibleInvitationCategory()">
                <span>Add Contacts</span>
              </li>
              <li @click="showDatePicker">
                <span>Date Range</span>
              </li>
              <a-range-picker
                v-if="isDatePickerVisible"
                status="warning"
                :ranges="{
                  Today: [moment(), moment()],
                  'This Month': [moment(), moment().endOf('month')],
                }"
                style="width: 100%;"
                @change="handleDateChange"
                :open="true"
              />
            </ul>
          </div>
        </button>
      </div>
    </div>
    <div class="collaborate-container__body">
      <div class="collaborate-container__body--search-bar">
        <input
          type="text"
          placeholder="Search"
          v-model="searchChat"
        />
        <a-tooltip placement="bottom">
          <template #title>New chat</template>
          <button class="add-new-chat-btn" @click="toggleToComment">
            <img src="@/assets/icons/new-chat-icon.png" alt="Icon" />
          </button>
        </a-tooltip>
      </div>
      <template v-if="searchChat.length !=0 && activeTab===''">
        <div
            class="collaborate-container__body--question-answer"
            v-for="message in filteredSessionChat"
            :key="message.id"
          >
            <div v-if="message.by === 'user'" class="my-question">
              <img :src="message.user.profile_picture" />
              <h5 v-html="message.highlightedMessage || message.message"></h5>
            </div>
            <ai-comment-card
              v-if="message.by === 'bot'"
              :botMessage="message"
              :highlightedMessage="message.highlightedMessage || message.message"
            ></ai-comment-card>
          </div>
      </template>

      <template
        v-if="getInterestGroup.length != 0 && toggleToComments === true"
      >
        <div
          class="collaborate-container__body--discussion-container"
          v-if="discoverTopicDiscussionLoader"
        >
          <a-skeleton
            v-for="discussionCard in 5"
            :key="discussionCard"
            active
            :avatar="{ shape: 'square' }"
            :title="{ width: '30%' }"
            :paragraph="{ rows: 2, width: ['100%', '50%'] }"
            class="discussion-card-skeleton"
          />
        </div>
        <div
          class="collaborate-container__body--discussion-container"
          v-if="
            !discoverTopicDiscussionLoader && discussionComments.length <= 0
          "
        >
          <h5 class="message">Content not found</h5>
        </div>
        <div ref="scrollDown"
          v-else-if="
            !discoverTopicDiscussionLoader && discussionComments.length > 0
          "
          class="collaborate-container__body--discussion-container"
        >
          <div ref="commentsContainer">
            <template v-for="comment in discussionComments" :key="comment.id">
              <discussion-card
                :discussionComment="comment"
                :closeEditLoader="closeEditLoader"
                :deleteLoader="deleteLoader"
                @deleteComment="deleteComment"
                @updateComment="updateComment"
                @replyComment="showReplyBox"
                @likeComment="likeComment"
                @deleteCommentLike="deleteCommentLike"
              ></discussion-card>
              <div class="reply-comment">
                <discussion-card
                  v-for="childComment in comment.child"
                  :key="childComment.id"
                  :discussionComment="childComment"
                  :closeEditLoader="closeEditLoader"
                  :deleteLoader="deleteLoader"
                  @deleteComment="deleteComment"
                  @updateComment="updateComment"
                  @likeComment="likeComment"
                  @deleteCommentLike="deleteCommentLike"
                  :showReplyCommentButton="false"
                ></discussion-card>
                <div v-if="comment.isReplying" class="input">
                  <img :src="loggedInUserProfile.profile_picture" />
                  <textarea
                    @keyup.enter="repliedComment"
                    name=""
                    id="commentTextarea"
                    cols="30"
                    rows="1"
                    placeholder="Add comment..."
                    v-model="repliedComment"
                  ></textarea>
                  <div class="buttons">
                    <a-button
                      :loading="replyLoading"
                      class="btn"
                      :class="{ disabled: isRepliedCommentDisabled }"
                      :disabled="isRepliedCommentDisabled"
                      @click="replyComment(comment)"
                    >
                      <i class="icon icon-send"></i>
                    </a-button>
                  </div>
                </div>
              </div>
            </template>
          </div>
        </div>
      </template>
      <div
        class="collaborate-container__body--default-message"
        v-if="
          !chatSkeletonLoader &&
            sessionChat.length === 0 &&
            toggleToComments === false
        "
      >
        <img src="@/assets/icons/glocal-ai-icon-navbar-black.svg" alt="Icon" />
        <h1 class="title">Welcome to Glocal AI</h1>
        <a-row :gutter="[18, 10]">
          <a-col :xs="24" :sm="12" :md="12" :lg="12" :xl="12">
            <div class="chat-message-card">
              <h1>Tell me some positive news stories</h1>
            </div>
          </a-col>
          <a-col :xs="24" :sm="12" :md="12" :lg="12" :xl="12">
            <div class="chat-message-card">
              <h1>Summarize key national security trends</h1>
            </div>
          </a-col>
          <a-col :xs="24" :sm="12" :md="12" :lg="12" :xl="12">
            <div class="chat-message-card">
              <h1>Most recent election poll results</h1>
            </div>
          </a-col>
          <a-col :xs="24" :sm="12" :md="12" :lg="12" :xl="12">
            <div class="chat-message-card">
              <h1>Tell me the top sports news updates</h1>
            </div>
          </a-col>
        </a-row>
      </div>
      <div v-if="!chatSkeletonLoader && toggleToComments === false && searchChat.length === 0">
        <div
          class="collaborate-container__body--question-answer"
          v-for="message in sessionChat"
          :key="message.id"
        >
          <div v-if="message.by === 'user'" class="my-question">
            <img :src="message.user.profile_picture" />
            <h5>{{ message.message }}</h5>
          </div>
          <ai-comment-card
            v-if="message.by === 'bot'"
            :botMessage="message"
          ></ai-comment-card>
        </div>
        <ai-comment-card v-if="botResponseLoader"></ai-comment-card>
      </div>
    </div>
    <div class="collaborate-container__footer">
      <div class="collaborate-container__footer--comment">
        <img :src="loggedInUserProfile.profile_picture" />
        <textarea
          v-model="comment"
          @keyup.enter="createComment"
          name=""
          id="commentTextarea"
          cols="30"
          rows="1"
          placeholder="Submit inquiry..."
        ></textarea>
        <div class="buttons">
          <a-button
            :loading="loading"
            @click="createComment()"
            :class="{ disabled: isDisabled }"
            :disabled="isDisabled"
            class="btn"
          >
            <i class="icon icon-send"></i>
          </a-button>
        </div>
      </div>
    </div>
  </div>
  <message-toaster
    :visible="showMessageModal"
    :message="message"
    @handle-close="showMessageModal = false"
  >
  </message-toaster>
  <invite-category
    :visible="inviteCategoryModal"
    @handle-close="inviteCategoryModal = false"
    @show-success-modal="showInvitationSubmit"
    :options="options"
    :categoryId="interestId"
    :interestGroupId="interestGroupId"
    :selectedTopic="topic.interest"
  ></invite-category>
  <collaborate-previous-searches-drawer
    :visible="showCollaboratePreviousSearchesDrawer"
    @close-drawer="closeCollaboratePreviousSearchesDrawer"
    @goToChat="toggleToComment"
  >
  </collaborate-previous-searches-drawer>
  <search-modal
    :visible="sowSearchDrawer"
    @close-drawer="closeChatSearchDrawer"
  />
</template>

<script>
import moment from "moment";
import debounce from 'lodash.debounce';
import { mapActions, mapGetters, mapMutations } from "vuex";
import DiscussionCard from "../TopicDiscussion/DiscussionCard.vue";
import SearchModal from "../BaseComponents/SearchModal.vue";
import AiCommentCard from "../GlocalAIPageComponents/AICommentCard.vue";
import inviteCategory from "../PdbComponents/InviteToDiscussionModal.vue";
import MessageToaster from "../BaseComponents/MessageToaster.vue";
import CollaboratePreviousSearchesDrawer from "./CollaboratePreviousSearchesDrawer.vue";
export default {
  data() {
    return {
      activeTab: "",
      isChat: false,
      searchChat: "",
      closeEditLoader: false,
      comment: "",
      toggleToComments: false,
      isPublicDropdownOpen: false,
      isPrivateDropdownOpen: false,
      deleteLoader: false,
      replyLoading: false,
      loading: false,
      showMessageModal: false,
      inviteCategory: [],
      inviteCategoryModal: false,
      repliedComment: "",
      isDatePickerVisible: false,
      sowSearchDrawer: false,
      showCollaboratePreviousSearchesDrawer: false,
      options:[]
    };
  },
  components: {
    CollaboratePreviousSearchesDrawer,
    AiCommentCard,
    DiscussionCard,
    SearchModal,
    inviteCategory,
    MessageToaster,
  },
  computed: {
    ...mapGetters({
      discoverTopicDiscussionLoader: "discussion/discoverTopicDiscussionLoader",
      sessionChat: "aiChat/sessionChat",
      chatSectionSkeleton: "aiChat/chatSectionSkeleton",
      botResponseLoader: "aiChat/botResponseLoader",
      topic:"discover/getTopicDetails"
    }),
    filteredSessionChat() {
      let filtered
      filtered = this.sessionChat.map((message) => {
        if (message.message.toLowerCase().includes(this.searchChat.toLowerCase())) {
          return {
            ...message,
            highlightedMessage: this.highlightText(message.message, this.searchChat),
          };
        }
      }).filter(msg => msg)
      return filtered
    },
    filteredInterest() {
      // Assuming you are using Vue Router to access the route params
      const routeId = this.$route.params.id;

      // Find the object in the array where the interest id matches the route id
      return this.permission.find((item) => item.interest.id === routeId);
    },
    categoryOptions() {
      console.log(this.$store.getters["profile/getUserInterests"])
      return this.$store.getters["profile/getUserInterests"];
    },
    isRepliedCommentDisabled() {
      return this.repliedComment.trim() === "";
    },
    loggedInUserProfile() {
      return this.$store.getters["profile/userInformation"].profile;
    },
    isDisabled() {
      return this.comment.trim() === "";
    },
    interestId() {
      return this.$route.params.id;
    },
    paramSessionId() {
      return this.$route.params.id;
    },
    chatSkeletonLoader() {
      return this.chatSectionSkeleton;
    },
    selectedSession() {
      return this.$store.getters["aiChat/activeSession"];
    },
    sessionChatLength() {
      return this.sessionChat.length;
    },
    interestGroupAudience() {
      var value = "";
      this.$store.getters["article/getInterestGroup"].forEach((element) => {
        value = element.is_private;
      });
      return value;
    },
    discussionComments() {
      return this.activeTab === "public"
        ? this.$store.getters["discussion/getPublicDiscussionComments"]
        : this.$store.getters["discussion/getPrivateDiscussionComments"];
    },
    getInterestGroup() {
      const resp = this.$store.getters["article/getInterestGroup"];
      resp.forEach((element) => {
        if (element.is_private) {
          this.privateTopicGroupId = element.id;
          // this.fetchComments('private')
        } else if (!element.is_private) {
          // this.fetchComments('public')
          this.publicTopicGroupId = element.id;
        }
      });
      return resp;
    },
    interestGroupId() { 
     return  this.activeTab === "private"
            ? this.privateTopicGroupId
            : this.publicTopicGroupId
    }
  },
  watch: {
    "$route.params.id"(newId, oldId) {
      this.comment = "";
      this.toggleToComments = false;
      this.clearSessionChat();
      this.clearInterestGroup();
      this.activeTab = "";
    },
    sessionChatLength(newVal, oldVal) {
      this.toggleToComments = false;
      this.activeTab = "";
    },
    activeTab(newVal) {
      this.isPublicDropdownOpen = false;
      this.isPrivateDropdownOpen = false;
    },
  },
  mounted() { 
    this.fetchContacts()
  },
  methods: {
    ...mapMutations({
      clearSessionChat: "aiChat/CLEAR_SESSION_CHAT",
      clearInterestGroup: "article/clearInterestGroup",
    }),
    moment,
    ...mapActions({
      getMessages: "aiChat/getMessages",
      setActiveSession: "aiChat/setActiveSession",
      emptySectionChats: "aiChat/emptySectionChats",
      toggleChatSectionSkeleton: "aiChat/toggleChatSectionSkeleton",
    }),
    scrollToElement() {
      this.$nextTick(() => {
          const el = this.$refs.commentsContainer;
          if (el) {
            el.scrollIntoView({behavior: "smooth", block: "end"});
          }
        });
      },
    highlightText(text, searchText) {
      // Highlight the matching text
      const regex = new RegExp(`(${searchText})`, 'gi');
      return text.replace(regex, '<span class="highlight">$1</span>');
    },
    async getSearchChat() {
      try {
        const payload = {
          search: this.searchChat,
        };
        const res = await this.$store.dispatch("aiChat/searchChat", payload);
        if (res.status === 200) {
          console.clear();
          console.log("res", res);
          this.sowSearchDrawer = true;
        }
      } catch (error) {
        console.log("err", error);
      }
    },
    debouncedSearch: debounce(function() {
      this.getSearchChat();
    }, 3000),
    showInvitationSubmit() {
      this.showMessageModal = true;
      this.message = {
        title: "Invitation Sent Successfully",
        type: "success",
      };
    },
    async handleDateChange(dates, dateStrings) {
      const [startDate, endDate] = dateStrings;
      await this.filterCommentsWithDate(startDate, endDate);
      this.isDropdownOpen = false;
      this.isDatePickerVisible = false;
    },
    async filterCommentsWithDate(startDate, endDate) {
      let interestOrFolderOrArticleId = null;
      interestOrFolderOrArticleId = this.interestId;
      const filterOptions = {
        startDate: startDate,
        endDate: endDate,
      };
      this.$store.dispatch("discussion/toggleDiscoverTopicCommentLoader");
      await this.fetchComments(
        null,
        interestOrFolderOrArticleId,
        filterOptions
      );
      this.$store.dispatch("discussion/toggleDiscoverTopicCommentLoader");
    },
    changeTab(tab) {
      this.activeTab = tab;
      this.toggleToComments = true;
      this.scrollToElement();
    },
    async replyComment(parentComment) {
      try {
        this.replyLoading = true;
        const payload = {
          comment: this.repliedComment,
          parent: parentComment.id,
          interest_id: parentComment.interest,
          interest_group_id:
            this.activeTab === "private"
              ? this.privateTopicGroupId
              : this.publicTopicGroupId,
        };
        const response = await this.$store.dispatch(
          "discussion/createDiscussionComment",
          payload
        );
        if (response.status === 201) {
          await this.fetchComments(parentComment);
          this.repliedComment = "";
        }
      } catch (err) {
        console.log(err);
      } finally {
        this.replyLoading = false;
      }
    },
    toggleToComment() {
      this.toggleToComments = false;
      this.activeTab = "";
      this.$store.commit("aiChat/CLEAR_SESSION_CHAT");
    },
    visibleInvitationCategory() {
      this.inviteCategoryModal = true;
      let obj = {
        id: this.interestId,
        name: this.interestName,
      };
      this.inviteCategory.push(obj);
    },
    async fetchComments(
      comment = null,
      interest_id = null,
      filterOptions = null
    ) {
      const payload = {
        interest_group_id:
          this.activeTab === "private"
            ? this.privateTopicGroupId
            : this.publicTopicGroupId,
        interest_id: comment ? comment.interest : interest_id,
        group: this.activeTab.toUpperCase(),
        filterOptions: filterOptions ? filterOptions : null,
      };
      const res = await this.$store.dispatch(
        "discussion/setDiscussionComments",
        payload
      );
    },
    scrollToBottom() {
      const chatContainer = this.$refs.commentsContainer;
      if (chatContainer) {
        console.log("chatContainer", chatContainer);
        chatContainer.scrollTop = chatContainer.scrollHeight - 100;
      }
    },
    async likeComment({ discussionComment, action }) {
      const payload = {
        interestGroupId:
          this.activeTab === "private"
            ? this.privateTopicGroupId
            : this.publicTopicGroupId,
        interestId: discussionComment.interest,
        commentId: discussionComment.id,
        action,
      };
      await this.$store.dispatch("discussion/likeComment", payload);
      this.discussionComments.forEach((comment) => {
        if (comment.id === discussionComment.id) {
          const counterAction = action.action === "like" ? "dislike" : "like";
          comment[`current_user_${action.action}`] = true;
          comment[`current_user_${counterAction}`] = null;
        }
      });
      this.fetchComments(discussionComment);
    },
    async deleteCommentLike(discussionComment) {
      if (this.$route.path.includes("discover")) {
        const payload = {
          interestGroupId:
            this.activeTab === "private"
              ? this.privateTopicGroupId
              : this.publicTopicGroupId,
          interestId: discussionComment.interest,
          commentId: discussionComment.id,
        };
        await this.$store.dispatch("discussion/deleteLikeDislike", payload);
        this.fetchComments(discussionComment);
      } else if (this.isArticleView) {
        const payload = {
          article_id: discussionComment.article_data,
          comment_id: discussionComment.id,
        };
        await this.$store.dispatch("discover/deleteLikeDislike", payload);
        this.discussionComments.forEach((comment) => {
          comment[`current_user_like`] = null;
          comment[`current_user_dislike`] = null;
        });
        this.fetchComments();
      } else {
        await this.$store.dispatch(
          "folders/deleteLikeDislike",
          discussionComment.id
        );
        this.fetchComments(discussionComment);
      }
    },
    showReplyBox(parentComment) {
      this.discussionComments.forEach((comment) => {
        if (comment.id === parentComment.id) {
          if (comment.isReplying) {
            comment.isReplying = false;
          } else {
            comment.isReplying = true;
          }
        } else {
          comment.isReplying = false;
        }
      });
    },
    async deleteComment(comment) {
      try {
        this.deleteLoader = true;
        if (this.$route.path.includes("discover")) {
          const payload = {
            discussion_id: comment.id,
            interest_id: comment.interest,
            interest_group_id:
              this.activeTab === "private"
                ? this.privateTopicGroupId
                : this.publicTopicGroupId,
            parent: comment.parent,
            group: this.activeTab.toUpperCase(),
          };
          const response = await this.$store.dispatch(
            "discussion/deleteDiscussionComments",
            payload
          );
          if (response.status === 204) {
            await this.fetchComments(comment);
            this.deleteLoader = false;
          }
        } else if (this.isArticleView) {
          try {
            const payload = {
              comment_id: comment.id,
              article_id: comment.article_data,
            };
            const response = await this.$store.dispatch(
              "discover/deleteDiscoverArticleComment",
              payload
            );
            if (response.status === 204) {
              await this.fetchComments();
              this.deleteLoader = false;
            }
          } catch (err) {
            console.log(err);
            this.deleteLoader = false;
          }
        } else {
          const payload = {
            commentId: comment.id,
            folderId: comment.folder,
          };
          const response = await this.$store.dispatch(
            "folders/deleteFolderComments",
            payload
          );
          if (response.status === 204) {
            await this.fetchComments(comment);
            this.deleteLoader = false;
          }
        }
      } catch (err) {
        console.log(err);
      }
    },
    async updateComment(comment) {
      try {
        this.closeEditLoader = false;
        if (this.$route.path.includes("discover")) {
          const payload = {
            comment: comment.editedComment,
            discussion_id: comment.id,
            interest_id: comment.interest,
            interest_group_id:
              this.activeTab === "private"
                ? this.privateTopicGroupId
                : this.publicTopicGroupId,
            group: this.activeTab.toUpperCase(),
          };
          const response = await this.$store.dispatch(
            "discussion/updateDiscussionCommentst",
            payload
          );
          if (response.status === 200) {
            this.fetchComments(comment);
            this.closeEditLoader = true;
          }
        } else if (this.isArticleView) {
          const payload = {
            comment: comment.editedComment,
            comment_id: comment.id,
            article_id: comment.article_data,
          };
          const response = await this.$store.dispatch(
            "discover/updateDiscoverArticleComment",
            payload
          );
          if (response.status === 200) {
            this.fetchComments();
            this.closeEditLoader = true;
          }
        } else {
          const form = new FormData();
          form.append("comment", comment.editedComment);
          const payload = {
            comment: form,
            commentId: comment.id,
            folderId: comment.folder,
          };
          const response = await this.$store.dispatch(
            "folders/updateFolderCommentst",
            payload
          );
          if (response.status === 200) {
            this.fetchComments(comment);
            this.closeEditLoader = true;
          }
        }
      } catch (err) {
        console.log(err);
        this.closeEditLoader = true;
      }
    },
    async createComment() {
      try {
        this.loading = true;
        if (this.toggleToComments === true) {
          const payload = {
            comment: this.comment,
            parent: null,
            interest_id: this.interestId,
            interest_group_id:
              this.activeTab === "private"
                ? this.privateTopicGroupId
                : this.activeTab === "public"
                ? this.publicTopicGroupId
                : this.topicGroupId,
          };
          const response = await this.$store.dispatch(
            "discussion/createDiscussionComment",
            payload
          );
          if (response.status === 201) {
            this.activeTab === "private"
              ? this.$store.dispatch("discussion/updateCommentList", {
                  group: "PRIVATE_DISCUSSION",
                  comment: response.data,
                })
              : this.$store.dispatch("discussion/updateCommentList", {
                  group: "PUBLIC_DISCUSSION",
                  comment: response.data,
                });
            this.comment = "";
            this.fetchComments(response.data);
          }
        } else {
          if (this.selectedSession) {
            const commentDetails = {
              message: this.comment,
              chat_bot_session: this.selectedSession.id,
            };
            await this.$store.dispatch("aiChat/createMessage", commentDetails);
            this.comment = "";
            // this.scrollToBottom()
          } else {
            this.$store.dispatch("aiChat/createSession", this.comment);
            this.comment = "";
          }
        }
      } catch (error) {
        console.log("err", error);
      } finally {
        this.loading = false;
      }
    },
    AddNewChat() {
      this.$store.commit("aiChat/CLEAR_SESSION_CHAT");
      this.toggleToComments = false;
      this.activeTab = "";
    },
    toggleDropdown(val) {
      if (val === "public") {
        this.isPublicDropdownOpen = !this.isPublicDropdownOpen;
      } else if (val === "private") {
        this.isPrivateDropdownOpen = !this.isPrivateDropdownOpen;
      }
    },
    showDatePicker() {
      this.isDatePickerVisible = !this.isDatePickerVisible;
    },
    closeCollaboratePreviousSearchesDrawer() {
      this.showCollaboratePreviousSearchesDrawer = false;
    },
    closeChatSearchDrawer() {
      this.sowSearchDrawer = false;
    },
    openCollaboratePreviousSearchesDrawer() {
      this.showCollaboratePreviousSearchesDrawer = true;
    },
    async fetchContacts() {
      try {
        this.options = [];
        const payload = {
          user_id: this.$store.getters["auth/getUserId"],
        };
        const response = await this.$store.dispatch(
          "profile/getFriendsList",
          payload
        );
        if (response.status == 200) {
          this.options = response.data.friends.map((val) => {
            if (val.profile) {
              let userList = {
                label: val.profile.full_name,
                value: val.id,
                key: val.email,
                icon: val.profile.profile_picture,
              };
              return userList;
            }
          });
        }
        // this.debounce(async () => {
        //   const payload = {
        //     user_id: this.$store.getters["auth/getUserId"],
        //   };
        //   const response = await this.$store.dispatch(
        //     "profile/getFriendsList",
        //     payload
        //   );
        //   if (response.status == 200) {
        //     this.options = response.data.friends.map((val) => {
        //       if (val.profile) {
        //         let userList = {
        //           label: val.profile.full_name,
        //           value: val.id,
        //           key: val.email,
        //           icon: val.profile.profile_picture,
        //         };
        //         return userList;
        //       }
        //     });
        //   }
        // }, 1000);
      } catch (err) {
        console.log(err);
      }
    },
  },
};
</script>

<style lang="scss">

.highlight {
  background-color: yellow;
}
.collaborate-container {
  border: 1px solid #d6d6d6;
  border-radius: 2rem;
  height: calc(100vh - 16.4rem);
  overflow: hidden;
  position: relative;
  margin: 0 1rem;
  @include respond(tab-port) {
    margin-top: 2rem;
  }
  &__header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-bottom: 1px solid #d6d6d6;
    padding: 1.9rem 2rem;
    &--title {
      color: $color-black;
      font-size: 2.2rem;
      line-height: 2.6rem;
      font-family: $font-primary-bold;
      margin-bottom: 0;
    }
    &--tabs {
      width: max-content;
      display: flex;
      align-items: center;
      .btn {
        display: flex;
        align-items: center;
        height: 3.8rem;
        padding: 0 1.3rem;
        border-radius: 100px !important;
        border: none;
        outline: none;
        cursor: pointer;
        background-color: #ebebeb;
        color: #434343;
        width: max-content;
        margin-left: 1rem;
        svg {
          width: 1.5rem;
          margin-right: 0.6rem;
          fill: #434343;
          height: auto;
          margin-bottom: 0.1rem;
        }
        .name {
          width: max-content;
          font-size: 1.4rem;
          line-height: 2rem;
          font-family: $font-primary-medium;
          margin-bottom: 0;
        }
        .dropdown {
          margin-left: auto;
          position: relative;
          display: block;
          @media (max-width: 1600px) {
            margin-bottom: 0.2rem;
            
          }
          &__dropdown-btn {
            background-color: transparent;
            border: none;
            outline: none;
            padding: 0;
            cursor: pointer;
            width: 1.3rem;
            margin-left: 0.5rem;
            height: auto;
            line-height: normal;
            .icon {
              font-size: 1.2rem;
              color: $color-black;
            }
            .icon-add_plus {
              transform: rotate(45deg);
            }
          }
          &__dropdown-menu {
            display: block;
            position: absolute;
            background-color: $color-white;
            box-shadow: 0px 4px 24.6px 0px #00000040;
            z-index: 3;
            right: 0;
            border-radius: 0.8rem;
            min-width: 13rem;
            li {
              padding: 1rem 2.2rem;
              cursor: pointer;
              transition: all 0.3s ease-in-out;
              list-style: none;
              display: block;
              width: 100%;
              font-family: $font-primary-medium;
              font-size: 1.6rem;
              line-height: normal;
              color: $color-black;
              border-bottom: 1px solid #0000001a;
              &:hover {
                background-color: #f5f5f5;
                border-radius: 1rem;
              }
              span {
                display: block;
                width: max-content;
              }
              &:last-child {
                border-bottom: none;
              }
              .ant-calendar-picker {
                .ant-calendar-picker-input,
                .ant-calendar-picker-icon {
                  display: none !important;
                }
              }
            }
          }
        }
      }
      .active {
        background-color: $color-primary;
        color: $color-white;
        svg {
          fill: $color-white;
        }
        .dropdown {
          &__dropdown-btn {
            .icon {
              color: $color-white;
            }
          }
        }
      }
      .glocal-ai-btn {
        border-radius: 100px !important;
        display: flex;
        align-items: center;
        height: 3.8rem;
        padding: 0 1.3rem;
        background-color: #ebebeb;
        button {
          border: none;
          outline: none;
          line-height: normal;
          background-color: transparent;
          cursor: pointer;
          img {
            width: 2.4rem;
            height: auto;
            margin-bottom: 0.2rem;
          }
        }
        .icon {
          cursor: pointer;
          font-size: 1.2rem;
          color: $color-black;
          margin-left: 0.5rem;
        }
      }
      .glocal-ai-btn-active {
        background-color: $color-primary;
        .icon {
          color: $color-white;
        }
      }
    }
  }
  &__body {
    position: relative;
    height: calc(100vh - 32.5rem);
    overflow-y: auto;
    &--search-bar {
      display: flex;
      align-items: center;
      gap: 1rem;
      background-color: $color-white;
      padding: 1.5rem 2rem 2rem;
      position: sticky;
      top: 0;
      z-index: 1;
      input {
        color: $color-black;
        font-size: 1.6rem;
        font-family: $font-primary;
        border: none;
        width: 100%;
        padding: 0 2rem;
        outline: none;
        border-radius: 0;
        background-color: #ebebeb;
        height: 3.8rem;
        border-radius: 100px !important;
        &::placeholder {
          opacity: 0.7;
        }
      }
      .add-new-chat-btn {
        height: auto;
        width: auto;
        line-height: normal;
        background-color: transparent;
        border: none;
        outline: none;
        cursor: pointer;
        img {
          width: 2.5rem;
        }
      }
    }
    &--default-message {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      margin-bottom: 1.5rem;
      width: 100%;
      position: absolute;
      bottom: 0;
      padding: 0 1rem;
      img {
        width: 4.5rem;
        height: auto;
        margin-bottom: 1rem;
      }
      .title {
        font-size: 2rem;
        font-family: $font-primary-bold;
        margin-bottom: 1.5rem;
        color: $color-black;
        line-height: 2.6rem;
        text-align: center;
      }
      .ant-row {
        width: 100%;
        margin-top: 1rem;
      }
      .chat-message-card {
        border: 1px solid $color-dark-grey-5;
        padding: 1rem 1.3rem;
        border-radius: 1rem;
        background-color: $color-white;
        h1 {
          font-size: 1.4rem;
          font-family: $font-primary-medium;
          margin-bottom: 0;
          color: $color-black;
          line-height: 1.8rem;
          text-align: left;
          overflow: hidden;
          display: -webkit-box;
          -webkit-line-clamp: 1; /* number of lines to show */
          line-clamp: 1;
          -webkit-box-orient: vertical;
          width: 100%;
        }
      }
    }
    &--question-answer {
      .my-question {
        display: flex;
        align-items: flex-start;
        padding: 0 2rem;
        img {
          width: 3rem;
          height: 3rem;
          border-radius: 100%;
          object-fit: fill;
          margin-right: 1rem;
          background-color: #e1e1e1;
        }
        h5 {
          font-size: 1.6rem;
          font-family: $font-primary-medium;
          margin-bottom: 0;
          color: $color-black;
          line-height: 2.2rem;
          margin-top: 0.5rem;
        }
      }
    }
    .ai-comment-card {
      margin: 1.5rem 0 2rem;
      padding: 0 2rem;
      &__ai-answer {
        img {
          width: 3rem;
          height: 3rem;
          margin-right: 1rem;
        }
        h4 {
          font-size: 1.6rem;
          line-height: 2.2rem;
          margin-top: 0.5rem;
        }
        .ant-dropdown-link {
          .icon {
            font-size: 1.6rem;
          }
        }
        &--typing {
          margin-top: 1.5rem;
          span {
            width: 0.6rem;
            height: 0.6rem;
            &:nth-child(2) {
              margin-left: 1rem * 1;
            }

            &:nth-child(3) {
              margin-left: 1rem * 2;
            }
          }
        }
      }
    }
    &--discussion-container {
      padding: 0 2rem;
      .discussion-card {
        padding: 1.5rem 1.5rem 1.6rem;
        margin: 0 0 2rem;
        &__header {
          margin-bottom: 1.5rem;
          &--image {
            width: 3rem;
            height: 3rem;
          }
          &--name-date {
            h2 {
              font-size: 1.4rem;
              line-height: 1.8rem;
            }
            p {
              font-size: 1.2rem;
              line-height: 1.6rem;
            }
          }
          &--like-ellipse {
            gap: 1.3rem;
            .btn {
              gap: 0.7rem;
              .icon {
                font-size: 1.6rem;
              }
              p {
                font-size: 1.4rem;
                line-height: 1.6rem;
              }
            }
            .ant-dropdown-link {
              margin-left: 0.5rem;
              .icon {
                font-size: 1.6rem;
              }
            }
          }
        }
        &__body {
          &--description {
            font-size: 1.6rem;
            line-height: 2.2rem;
            .link-preview {
              .link-card {
                margin: 1.5rem 0 1.7rem;
                &__image {
                  width: 6rem;
                  height: 5.9rem;
                }
                &__content {
                  padding: 1rem 1rem;
                  width: calc(100% - 6rem);
                  &--title {
                    font-size: 1.6rem;
                    line-height: 2rem;
                  }
                  &--author {
                    margin-top: 0.3rem;
                    p {
                      font-size: 1.2rem;
                      line-height: 1.6rem;
                    }
                  }
                }
              }
            }
          }
          &--reply-btn {
            .btn {
              font-size: 1.4rem;
              img {
                width: 1.4rem;
                height: auto;
              }
            }
          }
          &--edit {
            .btn-textarea {
              padding: 0.5rem 0.8rem;
              textarea {
                font-size: 1.6rem;
                line-height: 2.2rem;
              }
              .send-btn {
                .icon {
                  font-size: 1.6rem;
                }
              }
            }
          }
        }
      }
      .reply-comment {
        padding-left: 3rem;
        position: relative;
        margin-bottom: 2rem;
        .input {
          background-color: #f5f5f5;
          border: 1px solid #00000033;
          padding: 0 1.3rem;
          border-radius: 1rem;
          display: flex;
          align-items: center;
          img {
            width: 3rem;
            height: 3rem;
            border-radius: 100%;
            object-fit: fill;
            background-color: rgb(216, 216, 216);
          }
          textarea {
            padding: 0.8rem 1.5rem;
            margin: 0.5rem 0;
            border: none;
            font-size: 1.6rem;
            font-family: $font-primary;
            box-shadow: none;
            outline: none;
            line-height: 2.2rem;
            color: $color-black;
            background-color: transparent;
            resize: none;
            width: 100%;
            &::placeholder {
              color: #868686;
            }
            &::-webkit-scrollbar {
              display: none;
            }
          }
          .buttons {
            display: flex;
            align-items: center;
            .disabled {
              opacity: 0.5;
              cursor: not-allowed;
            }
            .ant-btn {
              width: 5rem;
              background-color: $color-primary;
              border: none;
              outline: none;
              height: 3rem;
              color: $color-white;
              border-radius: 100px !important;
              padding: 0 !important;
              span {
                line-height: normal;
              }
              .anticon {
                .anticon-spin {
                  font-size: 1.5rem;
                  color: $color-primary;
                }
              }
              &:hover,
              &:focus {
                background-color: $color-primary;
                color: $color-white;
              }
              .icon {
                font-size: 1.5rem !important;
                color: $color-white;
              }
            }
          }
        }
      }
      .message {
        color: $color-black;
        font-size: 1.6rem;
        line-height: 2rem;
        font-family: $font-primary;
        text-align: center;
        margin: 2rem 0 0;
      }
      .discussion-card-skeleton {
        margin-bottom: 1.5rem;
        .ant-skeleton-header {
          height: 3rem;
          width: 3rem;
          border-radius: 100%;
          padding: 0;
          .ant-skeleton-avatar {
            height: 3rem;
            width: 3rem;
            border-radius: 100%;
          }
        }
        .ant-skeleton-content {
          padding-left: 1rem;
          .ant-skeleton-title {
            margin: 0.7rem 0 1rem;
            height: 1.5rem;
          }
          .ant-skeleton-paragraph {
            margin: 0;
            li {
              margin: 0 0 0.5rem;
            }
          }
        }
      }
    }
    &::-webkit-scrollbar-track {
      background-color: transparent;
      width: 0.4rem;
      height: 0.4rem;
    }
    &::-webkit-scrollbar {
      width: 0.4rem;
      height: 0.4rem;
      background-color: transparent;
      border-radius: 3rem;
    }
    &::-webkit-scrollbar-thumb {
      background-color: $color-primary;
      border-radius: 3rem;
    }
  }
  &__footer {
    position: absolute;
    bottom: 0;
    width: calc(100% - 8rem);
    padding: 1.8rem 2rem;
    background-color: transparent;
    background-color: $color-white;
    width: 100%;
    &--comment {
      background-color: #eeeeee;
      padding: 0 1.4rem;
      border-radius: 1rem;
      display: flex;
      align-items: center;
      max-width: 88rem;
      margin: 0 auto;
      img {
        width: 3rem;
        height: 3rem;
        border-radius: 100%;
        object-fit: fill;
      }
      textarea {
        padding: 0.8rem 1.5rem;
        margin: 0.5rem 0;
        border: none;
        font-size: 1.6rem;
        font-family: $font-primary;
        box-shadow: none;
        outline: none;
        line-height: 2.2rem;
        color: $color-black;
        background-color: transparent;
        resize: none;
        width: 100%;
        &::placeholder {
          color: #868686;
        }
        &::-webkit-scrollbar {
          display: none;
        }
      }
      .buttons {
        display: flex;
        align-items: center;
        .disabled {
          opacity: 0.5;
          cursor: not-allowed;
        }
        .ant-btn {
          width: 5rem;
          background-color: $color-primary;
          border: none;
          outline: none;
          height: 3rem;
          color: $color-white;
          border-radius: 100px !important;
          padding: 0 !important;
          span {
            line-height: normal;
          }
          .anticon {
            .anticon-spin {
              font-size: 1.5rem !important;
              color: $color-primary;
            }
          }
          &:hover,
          &:focus {
            background-color: $color-primary;
            color: $color-white;
          }
          .icon {
            font-size: 1.5rem;
            display: block;
            color: $color-white;
          }
        }
      }
    }
  }
}
</style>
