<template>
  <div class="topic-discussion-date-select-dropdown">
    <button
      @click="toggleDropdown"
      class="topic-discussion-date-select-dropdown__dropdown-btn"
    >
      {{ selectedDate }}
      <img src="@/assets/icons/keyboard_arrow_down.svg" />
    </button>
    <ul
      v-if="isDropdownOpen"
      class="topic-discussion-date-select-dropdown__dropdown-menu"
    >
      <li @click="selectDate('Today')"><span>Today</span></li>
      <li @click="selectDate('Week')"><span>Week</span></li>
      <li @click="showDatePicker">
        <span>Date Range</span>
      </li>
      <a-range-picker
        v-if="isDatePickerVisible"
        status="warning"
        :ranges="{
          Today: [moment(), moment()],
          'This Month': [moment(), moment().endOf('month')],
        }"
        style="width: 100%;"
        @change="handleDateChange"
        :open="true"
      />
    </ul>
  </div>
</template>
<script>
import moment from "moment";
export default {
  data() {
    return {
      selectedDate: "Today",
      isDropdownOpen: false,
      isDatePickerVisible: false,
    };
  },

  methods: {
    moment,
    toggleDropdown() {
      this.isDropdownOpen = !this.isDropdownOpen;
      this.isDatePickerVisible = false;
    },
    selectDate(date) {
      this.selectedDate = date;
      this.isDropdownOpen = false;
      this.isDatePickerVisible = false;

      const today = new Date();
      const yesterday = new Date(today);
      switch (date) {
        case "Today":
          this.$emit("filterDate", {
            startDate: this.formatDate(today),
            endDate: this.formatDate(today),
          });
          break;
        case "Week":
          yesterday.setDate(today.getDate() - 6);
          this.$emit("filterDate", {
            startDate: this.formatDate(today),
            endDate: this.formatDate(yesterday),
          });
          break;
      }
    },
    showDatePicker() {
      this.isDatePickerVisible = !this.isDatePickerVisible;
    },
    handleDateChange(dates, dateStrings) {
      const [startDate, endDate] = dateStrings;
      const isSingleDate = startDate === endDate;
      this.selectedDate = isSingleDate
        ? startDate
        : `${startDate} to ${endDate}`;
      this.$emit("filterDate", {
        startDate,
        endDate,
      });
      this.isDropdownOpen = false;
      this.isDatePickerVisible = false;
    },
    formatDate(date) {
      let year = date.getFullYear();
      let day = String(date.getDate()).padStart(2, "0"); // Ensure two digits
      let month = String(date.getMonth() + 1).padStart(2, "0"); // Months are zero-based, so add 1
      return `${year}-${month}-${day}`;
    },
  },
};
</script>

<style lang="scss">
.topic-discussion-date-select-dropdown {
  margin-left: auto;
  position: relative;
  display: inline-block;
  padding-left: 2rem;
  &__dropdown-btn {
    background-color: #d6dcf0;
    border: none;
    outline: none;
    padding: 0.6rem 1.6rem;
    font-size: 1.4rem;
    line-height: 1.8rem;
    font-family: $font-primary-medium;
    cursor: pointer;
    color: $color-black;
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-radius: 100px !important;
    min-width: 11rem;
    width: max-content;
    margin-left: auto;
    img {
      width: 0.8rem;
      margin-left: 2.4rem;
    }
  }
  &__dropdown-menu {
    display: block;
    position: absolute;
    background-color: $color-white;
    box-shadow: 0px 4px 24.6px 0px #00000040;
    z-index: 1;
    right: 0;
    border-radius: 0.8rem;
    min-width: 13rem;
    li {
      padding: 1.3rem 1.6rem;
      cursor: pointer;
      transition: all 0.3s ease-in-out;
      list-style: none;
      display: block;
      width: 100%;
      font-family: $font-primary-medium;
      line-height: 1.4rem;
      font-size: 1.2rem;
      border-bottom: 1px solid #0000001a;
      &:hover {
        background-color: #f5f5f5;
      }
      span {
        display: block;
        width: max-content;
      }
      &:last-child {
        border-bottom: none;
      }
      .ant-calendar-picker {
        .ant-calendar-picker-input,
        .ant-calendar-picker-icon {
          display: none !important;
        }
      }
    }
  }
}
</style>
