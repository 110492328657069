<template>
  <a-drawer
    title="Searched Chat"
    placement="bottom"
    :closable="true"
    :visible="visible"
    @close="closeDrawer"
  >
    <div v-if="getSearchChat.length > 0">
      <div v-for="(item, index) in getSearchChat" :key="index">
        {{ item }}
      </div>
    </div>
    <div v-else>No search found</div>
  </a-drawer>
</template>

<script>
export default {
  props: {
    visible: Boolean,
  },
  data() {
    return {};
  },
  computed: {
    getSearchChat() {
      return this.$store.getters["aiChat/chatSearch"];
    },
  },
  watch: {},

  methods: {
    closeDrawer() {
      this.$emit("close-drawer");
    },
  },
};
</script>
