<template>
  <a-drawer
    title="Previous Searches"
    placement="left"
    :closable="true"
    :visible="visible"
    @close="closeDrawer"
    class="collaborate-previous-searches-drawer"
  >
    <div class="collaborate-previous-searches-drawer-container">
      <div class="collaborate-previous-searches-drawer-container__header">
        <div
          class="collaborate-previous-searches-drawer-container__header--search-bar"
        >
          <i class="icon icon-search" @click.stop=""></i>
          <input
            class="search-input"
            type="text"
            placeholder="Search"
            v-model="searchSession"
            @input="onInput"
          />
        </div>
      </div>
      <div class="collaborate-previous-searches-drawer-container__body">
        <div
          class="search-card"
          v-for="(searchCard, index) in sessionsList"
          :key="index"
        >
          <div @click="goToSession(searchCard.id)" class="search-card__details">
            <h1
              v-if="!searchCard.isEditing"
              class="search-card__details--title"
            >
              {{ searchCard.name }}
            </h1>
            <textarea
              v-if="searchCard.isEditing"
              v-model="newTitle"
              class="search-card__details--textarea"
              @keyup.enter="saveTitle(index)"
              @blur="saveTitle(index)"
              ref="textarea"
              rows="1"
            ></textarea>
            <button
              v-if="searchCard.isEditing"
              @click="cancelEdit(index)"
              class="search-card__details--cancel-btn"
            >
              Cancel
            </button>
            <p class="search-card__details--count">
              {{ searchCard.total_threads }} Conversation threads
            </p>
          </div>
          <a-dropdown
            :trigger="['click']"
            class="search-card__dropdown"
            placement="bottomRight"
          >
            <a class="dropdown-link" @click="toggleDropdown(index)">
              <i class="icon icon-vertical_three-dots"></i>
            </a>
            <template #overlay>
              <a-menu>
                <a-menu-item key="0" @click="editTitle(searchCard, index)">
                  <a>Rename</a>
                </a-menu-item>
                <a-menu-item key="1" @click="deleteCard(searchCard)">
                  <a class="remove-btn">Delete</a>
                </a-menu-item>
              </a-menu>
            </template>
          </a-dropdown>
        </div>
      </div>
    </div>
  </a-drawer>
  <delete-modal
    :visible="sessionDelete"
    @handle-close="sessionDelete = false"
    title="Are you sure you want to delete this Chat?"
    confirmText="Yes, Delete"
    cancelText="No, Don't Delete"
    :loading="sessionDelete"
    @delete-record="deleteSession"
  ></delete-modal>
</template>

<script>
import DeleteModal from "../BaseComponents/DeleteModal.vue";
import { mapActions, mapGetters } from "vuex";
export default {
  props: {
    visible: Boolean,
  },
  data() {
    return {
      sessions: [],
      searchSession: "",
      newTitle: "",
      editedTitle: "",
      searchCards: Array.from({ length: 10 }, (_, index) => ({
        title: `Search Title ${index + 1}`,
        newTitle: "",
        isEditing: false,
      })),
    };
  },
  components: {
    DeleteModal,
  },
  computed: {
    sessionDelete() {
      return this.$store.getters["aiChat/isDelete"];
    },
    sessionsList() {
      let list = this.$store.getters["aiChat/sessions"];
      if (this.searchSession.length > 0) {
        list = list.filter((session) => {
          if (
            session.name
              .toLowerCase()
              .includes(this.searchSession.toLowerCase())
          ) {
            return session;
          }
        });
      }
      return list;
    },
  },
  watch: {
    "$route.params.id": {
      handler(id) {
        if (id) {
          let session = this.sessionsList.find((session) => {
            if (session.id == id) {
              return session;
            }
          });
          this.setSession(session);
        } else {
          this.setSession();
        }
      },
      immediate: true,
    },
  },
  async beforeCreate() {
    await this.$store.dispatch("aiChat/initiateSessions");
    this.sessions = [...this.$store.getters["aiChat/sessions"]];
    if (this.$route.params.id) {
      const sectionId = this.$route.params.id;
      let session = this.sessionsList.find((session) => {
        if (session.id === sectionId) {
          return session;
        }
      });
      this.setSession(session);
    }
  },
  methods: {
    ...mapActions({
      getMessages: "aiChat/getMessages",
      setActiveSession: "aiChat/setActiveSession",
      emptySectionChats: "aiChat/emptySectionChats",
      toggleChatSectionSkeleton: "aiChat/toggleChatSectionSkeleton",
    }),
    updateUrl(id) {
      console.log("sessionId", id);
      // this.$emit("show-skeleton")
      // this.$router.push(`/glocal-AI/${id}`);
    },
    async goToSession(sessionId) {
      await this.getMessages(sessionId);
    },
    async deleteSession() {
      try {
        this.sessionDelete = true;
        await this.$store.dispatch("aiChat/deleteSession", this.session);
      } catch (error) {
        console.log("error", error);
      } finally {
        this.sessionDelete = false;
      }
    },
    closeDrawer() {
      // Emit the event to the parent to notify about closing
      this.$emit("close-drawer");
    },
    async setSession(session = null) {
      if (session) {
        this.setActiveSession(session);
        this.toggleChatSectionSkeleton();
        this.emptySectionChats();
        await this.getMessages(session.id);
        this.toggleChatSectionSkeleton();
      } else {
        this.setActiveSession(null);
        this.emptySectionChats();
      }
    },
    editTitle(session, index) {
      this.sessionsList[index].isEditing = true;
      this.newTitle = session.name;
      this.editedTitle = session.name;
    },
    // editTitle(index) {
    //   this.searchCards[index].isEditing = true;
    //   this.searchCards[index].newTitle = this.searchCards[index].title;
    //   this.$nextTick(() => {
    //     this.$refs.textarea[index].focus();
    //   });
    // },
    // saveTitle(index) {
    //   if (this.searchCards[index].newTitle.trim()) {
    //     this.searchCards[index].title = this.searchCards[index].newTitle.trim();
    //   }
    //   this.searchCards[index].isEditing = false;
    // },
    saveTitle(index) {
      if (this.sessionsList[index].name.trim() !== "") {
        this.sessionsList[index].name = this.newTitle;
        this.$store.dispatch(
          "aiChat/editSessionName",
          this.sessionsList[index]
        );
      }
      this.sessionsList[index].isEditing = false;
    },
    cancelEdit(index) {
      this.newTitle = this.editedTitle;
      this.sessionsList[index].isEditing = false;
    },
    // deleteCard(index) {
    //   this.searchCards.splice(index, 1);
    // },
    deleteCard(session) {
      this.$store.commit("aiChat/SESSION_DELETE", true);
      this.session = session;
    },
    toggleDropdown(index) {
      // Implement dropdown toggle functionality if needed
    },
  },
};
</script>

<style lang="scss">
.collaborate-previous-searches-drawer {
  .ant-drawer-content-wrapper {
    height: 100vh !important;
    width: 40rem !important;
    .ant-drawer-content {
      .ant-drawer-wrapper-body {
        overflow: hidden;
        .ant-drawer-header {
          padding: 3.3rem 2.5rem 2.4rem;
          border: none;
          .ant-drawer-title {
            font-size: 2.4rem;
            font-family: $font-primary-bold;
            opacity: 1;
            color: $color-black;
          }
          .ant-drawer-close {
            width: 2rem;
            height: 2rem;
            line-height: normal;
            right: 2.5rem;
            top: 3.3rem;
            color: $color-black;
            opacity: 1;
          }
        }
        .ant-drawer-body {
          height: 100%;
          padding: 0 2rem;
          .collaborate-previous-searches-drawer-container {
            &__header {
              padding-bottom: 2.9rem;
              padding-right: 1rem;
              &--search-bar {
                width: 100%;
                margin: 0;
                display: flex;
                align-items: center;
                background-color: #e9e9e9;
                padding: 0 1.5rem 0 1rem;
                border-radius: 100px;
                .icon {
                  font-size: 1.7rem;
                  opacity: 1;
                  color: $color-primary;
                  margin-top: 0rem;
                }
                .search-input {
                  font-size: 1.2rem;
                  font-family: $font-primary;
                  line-height: normal;
                  padding: 1.2rem 1rem;
                  border: none;
                  outline: none;
                  width: 100%;
                  background-color: transparent;
                  &::placeholder {
                    color: $color-black;
                    opacity: 0.5;
                  }
                }
                .filter-dropdwon {
                  margin-left: auto;
                  position: relative;
                  display: inline-block;
                  padding-left: 2rem;
                  &__btn {
                    background-color: transparent;
                    border: none;
                    outline: none;
                    padding: 0;
                    cursor: pointer;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    width: max-content;
                    margin-left: auto;
                    img {
                      width: 1.8rem;
                      margin-left: 2.4rem;
                    }
                  }
                  &__dropdown-menu {
                    display: block;
                    position: absolute;
                    background-color: $color-white;
                    box-shadow: 0px 4px 24.6px 0px #00000040;
                    z-index: 1;
                    right: 0;
                    border-radius: 0.8rem;
                    li {
                      padding: 1.3rem 1.6rem;
                      cursor: pointer;
                      transition: all 0.3s ease-in-out;
                      list-style: none;
                      display: block;
                      width: 100%;
                      font-family: $font-primary-medium;
                      line-height: 1.4rem;
                      font-size: 1.2rem;
                      border-bottom: 1px solid #0000001a;
                      border-radius: 0.8rem;
                      &:hover {
                        background-color: #f5f5f5;
                      }
                      span {
                        display: block;
                        width: max-content;
                      }
                      &:last-child {
                        border-bottom: none;
                      }
                      .ant-calendar-picker {
                        .ant-calendar-picker-input,
                        .ant-calendar-picker-icon {
                          display: none !important;
                        }
                      }
                    }
                  }
                }
              }
            }
            &__body {
              overflow-y: auto;
              overflow-x: hidden;
              height: calc(100vh - 18rem);
              padding-right: 1rem;
              .search-card {
                border: 1px solid $color-dark-grey-5;
                padding: 1.5rem 1.8rem;
                background-color: #f4f4f4;
                border-radius: 1rem;
                display: flex;
                align-items: flex-start;
                margin-bottom: 2.4rem;
                cursor: pointer;
                &__details {
                  width: 100%;
                  padding-right: 1rem;
                  &--title {
                    font-size: 1.4rem;
                    font-family: $font-primary-medium;
                    margin-bottom: 1rem;
                    color: $color-black;
                    line-height: 1.8rem;
                    overflow: hidden;
                    display: -webkit-box;
                    -webkit-line-clamp: 2;
                    line-clamp: 2;
                    -webkit-box-orient: vertical;
                  }
                  &--textarea {
                    padding: 0;
                    width: 100%;
                    font-size: 1.4rem;
                    font-family: $font-primary-medium;
                    color: $color-black;
                    line-height: 1.8rem;
                    overflow: hidden;
                    resize: none;
                    margin-bottom: 1rem;
                    border: none;
                    outline: 1px solid $color-primary;
                    background-color: transparent;
                    box-sizing: border-box;
                    display: block;
                  }
                  &--cancel-btn {
                    font-size: 1rem;
                    line-height: 1rem;
                    margin-bottom: 0.5rem;
                    margin-top: -0.2rem;
                    display: block;
                    border: none;
                    font-family: $font-primary-medium;
                    margin-left: auto;
                    cursor: pointer;
                  }
                  &--count {
                    font-size: 1.2rem;
                    font-family: $font-primary;
                    margin-bottom: 0.2rem;
                    color: $color-black;
                    line-height: 1.4rem;
                    opacity: 0.6;
                  }
                }
                &__dropdown {
                  margin-right: -0.2rem;
                  .icon {
                    font-size: 1.5rem;
                    color: $color-black;
                  }
                }
              }
              &::-webkit-scrollbar-track {
                background-color: #f4f4f4;
                width: 4px;
                height: 4px;
              }
              &::-webkit-scrollbar {
                width: 4px;
                height: 4px;
                background-color: rgb(224, 224, 224);
                border-radius: 3rem;
              }
              &::-webkit-scrollbar-thumb {
                background-color: $color-primary;
                border-radius: 3rem;
              }
            }
          }
        }
      }
    }
  }
}
</style>
